import { Text, TextProps } from 'grommet'
import React from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../cash-register/common/formatBRL'
import { DateFormat, TimeZone, formatDate } from '../../common/FormatDate'
import { calculateTxCreditTotalAmount } from '../../common/TransactionalCreditHelper'
import { TransactionalCreditEvaluation } from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { gray, green } from '../../ui/utils/_colors'

export interface TxCreditCardProps {
  availableLimit?: number,
  evaluation: TransactionalCreditEvaluation
}

const TxCreditCard: React.FC<TxCreditCardProps> = props => {
  const { availableLimit, evaluation } = props

  const totalAmount = calculateTxCreditTotalAmount(evaluation.policy!!.maxAmount, (availableLimit || 0))

  return (
    <Card>
      <CardContainer>
        <CardHeader>
          <Title>⭐️ Limite extra para o cliente!</Title>
        </CardHeader>

        <CardInfoContainer>
          <InfoDescription weight={'bold'} color={'#191919'}>Limite total disponível</InfoDescription>
          <InfoValue color={green.dark1} >{formatBRL(totalAmount)}</InfoValue>
        </CardInfoContainer>

        <StraightLine />

        <CardInfoContainer>
          <InfoDescription>Limite extra aprovado</InfoDescription>
          <InfoValue>{formatBRL(evaluation.policy!!.maxAmount)}</InfoValue>
        </CardInfoContainer>

        <CardInfoContainer>
          <InfoDescription>Limite Ume</InfoDescription>
          <InfoValue>{formatBRL(availableLimit || 0)}</InfoValue>
        </CardInfoContainer>

        <AlertInfoContainer>
          <Subtitle>Importante:</Subtitle>
          <>
            <Row>
              {`\u2022`}
              <AlertInfoMessage>
                O limite extra disponibilizado é válido para uma <b>única compra</b> em sua loja.
              </AlertInfoMessage>
            </Row>
            <Row>
              {`\u2022`}
              <AlertInfoMessage>
                O limite extra expira em <b>{formatDate(evaluation.expirationTimestamp, DateFormat.BR_DATE_FORMAT, TimeZone.MANAUS)}</b>.
              </AlertInfoMessage>
            </Row>
            <Row>
              {`\u2022`}
              <AlertInfoMessage>
                A proposta para este cliente já se encontra ativa no caixa.
              </AlertInfoMessage>
            </Row>
          </>
        </AlertInfoContainer>
      </CardContainer>
    </Card>
  )
}

export default TxCreditCard

const Card = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  border-radius: 16px;
  border: 1px solid ${green.dark1};
  background: ${green.background2};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  padding: 10px 16px;
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  padding: 16px 0px;
`

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const StraightLine = styled.div`
  width: 100%;
  margin: 10px 0px;
  border: 1px solid #F2F2F2;
`

const InfoDescription = styled(Text) <TextProps>`
  font-size: 14px;
  font-weight: ${(props: TextProps) => props.weight ?? 'normal'};
  text-align-vertical: center;
  color: ${(props: TextProps) => props.color ?? gray.text};
`

const InfoValue = styled(Text) <TextProps>`
  font-size: 14px;
  font-weight: 550;
  text-align-vertical: center;
  color: ${(props: TextProps) => props.color ?? gray.text};
`

const Title = styled.text`
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  color: black;
  text-align: left;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const AlertInfoMessage = styled.text`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding-left: 4px;
  color: ${gray.primary};
`

const AlertInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  margin: 20px 0px;
`

const Subtitle = styled.text`
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  font-weight: bold;
  line-height: 20px;
  padding-bottom: 10px;
`