import * as React from 'react'
import { NumberFormatValues } from 'react-number-format'
import styled from 'styled-components'
import { validate_cpf } from '../../common/Validators'
import NumberField from '../Application/NumberField'

interface CpfInputFormPropos {
  document: string
  onValueChange?: (values: NumberFormatValues) => void
  icon?: any
}

export const CpfInputForm: React.FC<CpfInputFormPropos> = (props: CpfInputFormPropos) => {
  const documentErrorMessage = 'Digite um CPF válido.'
  const { icon, onValueChange, document } = props
  const isValidDocument = validate_cpf(document)

  return (
    <>
      <Title>Insira o CPF do cliente</Title>
      <FormField
        Icon={icon}
        label="CPF"
        value={document}
        format="###.###.###-##"
        placeholder="000.000.000-00"
        onValueChange={onValueChange}
        isValidated={isValidDocument}
        error={document.length > 0 && !isValidDocument}
        helperText={!isValidDocument ? documentErrorMessage : ''}
      />
    </>
  )
}

const FormField = styled(NumberField).attrs({
  type: 'tel',
  mask: '_',
  style: { width: '100%', marginTop: '10%' },
  autoFocus: true,
})``

const Title = styled.text`
  font-family: Roboto;
  font-size: 22px;
  line-height: 28px;
  font-weight: bold;
  color: black;
  text-align: left;
  padding-top: 0.5rem;
`
