import axios from 'axios'
import { bffParceirosApiService } from '.'
import { history } from '../../App'
import { getConfig } from '../../config/config'

type UmeAxiosInstanceProps = {
  baseURL: string
  token?: string | null
}

export const getAxiosInstance = (props: UmeAxiosInstanceProps) => {
  const { baseURL, token } = props

  const axiosInstance = axios.create({
    baseURL,
    timeout: 300000, // 5 minutes
    maxRedirects: 5,
  })

  // injects Authorization header
  axiosInstance.interceptors.request.use(async config => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  })

  // TODO - This is incorrect. All UNAUTHORIZED of FORBIDDEN will logout user.
  // logout the application when token expires
  axiosInstance.interceptors.response.use(undefined, async error => {
    if (error.config && error.response && error.response.status === 401) {
      console.warn('Logging out')
      await bffParceirosApiService.auth.logout()
      history.push('/login')
      return Promise.reject({ message: 'UNAUTHORIZED' })
    } else if (error.response && error.response.status === 403) {
      return Promise.reject({ message: 'FORBIDDEN' })
    }
    return Promise.reject(error)
  })

  return axiosInstance
}

