import React from 'react'
import styled from 'styled-components'

interface Props {
  label: string
  value: number | string | React.ReactElement<any, any>
}

export const ListItem: React.FC<Props> = props => {
  const { label, value } = props

  return (
    <ListContainer>
      <Text>{label}</Text>
      <Value>{value}</Value>
    </ListContainer>
  )
}

export default ListItem

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right as right;
  padding: 0.2em 1em 0.2em 1em;
  font-size: 20px;
`

const Text = styled.text`
  font-size: 16px;
  color: #757e79; //dark grey
`

const Value = styled.text`
  font-size: 16px;
  font-weight: bold;
`
