import { Divider } from '@blueprintjs/core'
import React from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../cash-register/common/formatBRL'
import { mobile } from '../../common/assets/utils/_breakpoints'
import { DueDateRule } from '../../services/bff/coordinator/dto/Contracts.dto'
import CustomButton from '../../ui/Button/CustomButton'
import { green, white } from '../../ui/utils/_colors'
import ListItem from './components/ListItem'
import PurchaseDetailsDropdown from './PurchaseDetailsDropdown'

interface Props {
  purchaseValue: string
  interestRate: number
  iof: number
  installments: number
  installmentValue: number
  installmentValueWithoutInterest?: number
  couponDiscount: number
  firstInstallmentDueDate?: string
  selectedPurchaseCondition: number
  openVerificationDialog: () => void
  customerFeeRate?: number
  dueDateRule?: DueDateRule
}

export const PurchaseResumeCard: React.FC<Props> = props => {
  const {
    purchaseValue,
    interestRate,
    iof,
    installments,
    installmentValue,
    couponDiscount,
    firstInstallmentDueDate,
    selectedPurchaseCondition,
    openVerificationDialog,
    installmentValueWithoutInterest,
    customerFeeRate,
    dueDateRule,
  } = props

  return (
    <PurchaseResumeContainer>
      <Title>Resumo do contrato</Title>
      <PurchaseInfoGroup>
        <ListItem label={'Valor do contrato'} value={formatBRL(installmentValue * installments + couponDiscount)} />

        {couponDiscount > 0 && (
          <ListItem label={'Cupom aplicado'} value={<CuponValue>- {formatBRL(couponDiscount)}</CuponValue>} />
        )}

        <Divider />

        <ListItem
          label={'Total a pagar'}
          value={<PurchaseFinalValue>{formatBRL(installmentValue * installments)}</PurchaseFinalValue>}
        />

        <FinalizePurchaseButton
          width="100%"
          height="56px"
          borderRadius="8px"
          disabled={selectedPurchaseCondition === -1}
          tabIndex={1}
          onClick={openVerificationDialog}
        >
          Finalizar compra
        </FinalizePurchaseButton>

        <PurchaseDetailsDropdown
          purchaseValue={purchaseValue}
          interestRate={interestRate}
          iof={iof}
          firstInstallmentDueDate={firstInstallmentDueDate}
          couponDiscount={couponDiscount}
          installmentValue={installmentValue}
          installmentValueWithoutInterest={installmentValueWithoutInterest}
          customerFeeRate={customerFeeRate}
          dueDateRule={dueDateRule}
        />
      </PurchaseInfoGroup>
    </PurchaseResumeContainer>
  )
}

export default PurchaseResumeCard

const PurchaseResumeContainer = styled.div`
  width: 90%;
  margin: 20px;
  border-radius: 20px;
  background-color: white;
`

const PurchaseInfoGroup = styled.div`
  padding: 25px;

  @media (max-width: ${mobile}) {
    padding: 10px 0px 0px 0px;
  }
`

const Title = styled.div`
  width: 100%;
  padding-top: 25px;
  font-size: 18px;
  font-weight: bold;
`

const CuponValue = styled.text`
  font-weight: bold;
  color: #46db6b; // light green
`

const PurchaseFinalValue = styled.text`
  font-size: 24px;
`

const FinalizePurchaseButton = styled(CustomButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-color: ${green.primary};
  background-color: ${green.primary};
  color: ${white.primary};
  margin: 12px 0px 0px 0px;
  font-size: 18px;
`
