import { AnchorProps } from 'grommet'
import React, { Component } from 'react'
import { StyledAnchor } from './styles'

interface StyledAnchorProps extends AnchorProps {
  bold?: boolean
  fontSize?: string
}

export default class Anchor extends Component<StyledAnchorProps & Omit<JSX.IntrinsicElements['a'], 'color'>> {
  render() {
    return <StyledAnchor {...this.props}>{this.props.children}</StyledAnchor>
  }
}
