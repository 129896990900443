import React, { PureComponent } from 'react'
import { Icon } from './styles'

export default class Barcode extends PureComponent {
  render() {
    return (
      <Icon {...this.props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            fill="none"
            d="M4,6v12h16.001L20,6H4z M8,16H6V8h2V16z M10,16H9V8h1V16z M12,16h-1V8h1V16z M16,16h-3V8h3V16z M18,16h-1V8h1 V16z"
          />
          <path d="M20,4H4C2.897,4,2,4.897,2,6v12c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2V6C22,4.897,21.103,4,20,4z M4,18V6h16 l0.001,12H4z" />
          <path d="M6 8H8V16H6zM9 8H10V16H9zM17 8H18V16H17zM13 8H16V16H13zM11 8H12V16H11z" />
        </svg>
      </Icon>
    )
  }
}
