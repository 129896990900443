import React from 'react'
import { useDispatch } from 'react-redux'
import { history } from '../../../App'
import FeatureFlags from '../../../common/assets/utils/featureFlags'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'
import BudgetSliceReducer from '../../../redux/reducers/budget/budget.reducer'
import InvoicesSliceReducer from '../../../redux/reducers/invoice/invoices.reducer'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../../services/bff'
import { Calculator } from '../../../ui/Icons'
import Barcode from '../../../ui/Icons/Barcode'
import { blue, gray } from '../../../ui/utils/_colors'

interface Props {
  document: string
  borrowerId?: string
}

export const UserAvailableProducts = (props: Props) => {
  const { isAllowedTransactionalCredit, isCreatingBorrowerNextInvoice } = useTypedSelector(state => ({
    isAllowedTransactionalCredit: state.configurations?.configurations?.transactionalCredit?.isAllowed,
    isCreatingBorrowerNextInvoice: state.invoices.isCreatingBorrowerNextInvoice,
  }))

  const dispatch = useDispatch()

  const store = bffParceirosApiService.coordinator.getStore()

  const handleTransactionalCreditButton = () => {
    dispatch(BudgetSliceReducer.actions.resetRequest())
    history.push(PagesPathEnum.TRANSACTIONAL_CREDIT_PAGE)
  }

  const handleBudgetButton = () => {
    dispatch(BudgetSliceReducer.actions.resetRequest())
    dispatch(
      BudgetSliceReducer.actions.setInitialCpf({
        initialCpf: props.document,
      })
    )

    history.push(PagesPathEnum.BUDGET_PAGE)
  }

  const handleGenerateBorrowerNextInvoiceButton = (borrowerId: string) => {
    dispatch(InvoicesSliceReducer.actions.createBorrowerNextInvoice(borrowerId))
  }

  const shouldRenderTransactionalCreditButton =
    FeatureFlags.allowTransactionalCreditEvaluation() && isAllowedTransactionalCredit

  const shouldRenderGenerateBorrowerNextInvoiceButton =
    !!store?.settings?.enableToGenerateBorrowerInvoice && !!props.borrowerId

  return [
    // {
    //   shouldRender: shouldRenderTransactionalCreditButton,
    //   title: 'Super Compra Ume',
    //   highlightSubTitle: '💡 Dica Ume: ',
    //   subTitle: 'Você pode tentar ofertar a Super Compra para este cliente.',
    //   icon: <Dollar />,
    //   iconColor: green.dark1,
    //   backgroundColor: green.light2,
    //   onButtonPress: handleTransactionalCreditButton,
    // },

    {
      shouldRender: true,
      title: 'Calculadora de Parcelas',
      icon: <Calculator />,
      iconColor: blue.dark1,
      backgroundColor: gray.light5,
      onButtonPress: handleBudgetButton,
      isLoading: false,
    },
    {
      shouldRender: shouldRenderGenerateBorrowerNextInvoiceButton,
      title: 'Imprimir próximo boleto',
      icon: <Barcode />,
      iconColor: blue.dark1,
      backgroundColor: gray.light5,
      onButtonPress: () => handleGenerateBorrowerNextInvoiceButton(props.borrowerId!),
      isLoading: isCreatingBorrowerNextInvoice,
    },
  ]
}
