import { CSSProperties } from 'react'
import styled from 'styled-components'
import CustomButton from '../../ui/Button/CustomButton'
import { green, white } from '../../ui/utils/_colors'

export const purchseValueGroup = {
  display: 'flex',
  marginBottom: '16px',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
}

export const redSpan: CSSProperties = {
  color: 'white',
  textAlign: 'center',
  backgroundColor: '#F61E61',
  width: '50%',
}

export const orangeSpan: CSSProperties = {
  color: 'white',
  textAlign: 'center',
  backgroundColor: '#FFA500',
  width: '50%',
}

export const spanContainer: CSSProperties = {
  display: 'flex',
  justifyContent: 'center'
}
