import * as yup from 'yup';
import moment from 'moment';
import { validateBr } from '../common/Validators';
import { NumberFormatValues } from 'react-number-format';
import { AnyObject, ObjectShape, RequiredObjectSchema, TypeOfShape } from 'yup/lib/object';

export const cpfSchema = yup.object().required().test(function ({ value }) {
  if (!value) {
    return this.createError({
      message: 'Campo obrigatório',
      path: 'cpf'
    });
  } else if (!validateBr.cpf(value) || String(value).length < 11) {
    return this.createError({
      message: 'Digite um CPF válido',
      path: 'cpf'
    });
  } return true
})

export const minValueSchema = (
  minValue: number,
  attribute: string,
  errorMessage?: string
): RequiredObjectSchema<ObjectShape, AnyObject, TypeOfShape<ObjectShape>> => {
  return yup.object().required().test(function ({ floatValue }) {
    if (!floatValue) {
      return this.createError({
        message: 'Campo obrigatório',
        path: attribute
      });
    } else if (floatValue < minValue) {
      return this.createError({
        message: errorMessage?errorMessage:`Digite um valor maior que ${minValue}`,
        path: attribute
      });
    }
    return true
  })
}

export const phoneSchema = yup
  .string()
  .matches(/\(\d{2}\)\s\9\d{4}\-\d{4}|^$/, 'Celular inválido.');

export const mandatoryNameSchema = (attribute: string, error?: string): yup.StringSchema => {
  return yup.string().test(function (value) {
    const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
    const twoWordsRegex = /(\w.+\s).+/i;
    if (!nameRegex.test(value as string) || !value) {
      return this.createError({
        message: error ? error : 'Campo inválido.',
        path: attribute
      });
    } else if (!String(value).trim()) {
      return this.createError({
        message: 'Este campo não deve conter apenas espaços em branco.',
        path: attribute
      });
    } else if (!twoWordsRegex.test(value as string)) {
      return this.createError({
        message: 'Você deve inserir seu nome completo.',
        path: attribute
      });
    }

    return true;
  });
};

export const mandatoryStringSchema = (attribute: string): yup.StringSchema => {
  return yup.string().test(function (value) {
    if (!value) {
      return this.createError({
        message: 'Campo obrigatório.',
        path: attribute
      });
    } else if (!String(value).trim()) {
      return this.createError({
        message: 'Este campo não deve conter apenas espaços em branco.',
        path: attribute
      });
    }

    return true;
  });
};

export const emailSchema = yup
  .string()
  .email('E-mail inválido')
  .required('Campo obrigatório.');

export const cepSchema = yup
  .string()
  .min(8, 'Insira um cep válido.')
  .matches(/^\d{5}-\d{3}$/, 'Insira um cep válido.');

export const objectSchema = yup
  .object()
  .required('Campo obrigatório');

export const dateSchema = (attribute: string): yup.StringSchema => {
  return yup.string().test(function (value) {
    if (value === '') return true;

    const year = value && value.split('/')[2] ? value.split('/')[2] : '';
    if (!moment(value, 'DD/MM/YYYY', true).isValid() || year === '0000') {
      return this.createError({
        message: 'Data inválida (DD/MM/YYYY) ou não existe.',
        path: attribute
      });
    }

    if (
      moment().diff(moment(value, 'DD/MM/YYYY')) < 0
    ) {
      return this.createError({
        message: 'A data de nascimento não pode estar no futuro.',
        path: attribute
      });
    }
    return true;
  });
};
