import axios, { AxiosInstance } from 'axios'
import { useMutation } from 'react-query'
import { getAxiosInstance } from '../utils'
import {
  CreateSmartphoneSaleCreditEvaluationRequest,
  CreateSmartphoneSaleCreditEvaluationResponse,
  SmartphoneSaleIMEIVerifyRequest,
} from './dtos/smartphone-sale-credit-evaluation.dto'

export default class SmartphoneSaleCreditService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  useCreateSmartphoneSaleCreditEvaluation = () => {
    return useMutation<
      CreateSmartphoneSaleCreditEvaluationResponse,
      Error,
      CreateSmartphoneSaleCreditEvaluationRequest
    >({
      retry: false,
      mutationKey: ['createSmartphoneSaleCreditEvaluation'],
      mutationFn: (payload: CreateSmartphoneSaleCreditEvaluationRequest) => {
        return this.axiosInstance
          .post(`/smartphone-sale/evaluations`, { ...payload })
          .then(res => res.data)
          .catch(_error => {
            throw new Error(_error?.message ?? 'UNEXPECTED_ERROR')
          })
      },
    })
  }

  async smartphoneSaleIMEIVerify(body: SmartphoneSaleIMEIVerifyRequest): Promise<void> {
    const response = await this.axiosInstance.post<void>(`/smartphone-sale/evaluations/verify`, body)

    return response.data
  }
}
