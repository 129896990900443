const colors = {
  blue: {
    dark: '#0000ff',
    medium: '#3784be',
    primary: '#8cafe6',
    bay: '#1F13AB',
    teal: '#1392AB',
    azure: '#0184FF',
    navy: '#1B73E8',
    vivid: '#0021FF',
  },
  gray: {
    primary: '#808080',
    light: '#bebebe',
    light1: '#F8F8F8',
    light3: '#EDEDED',
    alto: '#DADADA',
    gainsboro: '#E3E3E3',
    eclipse: '#3A3A3A',
    aluminium: '#80868C',
    ivory: '#F6F6F4',
    nobel: '#949494',
    dim: '#6A6A6A',
    azure: '#F5F6F6',
    spanish: '#9D9D9D',
  },
  yellow: {
    primary: '#FFC960',
    light: '#FFFF99',
    dark: '#CCCC00',
    citrine: '#F7B500',
  },
  green: {
    primary: '#28C264',
    light: '#7FFF00',
    dark: '#006400',
    seagreen: '#8FBC8F',
    sushi: '#8BC34A',
    darkPastel: '#00C745',
    laurel: '#048F0E',
  },
  black: {
    primary: '#000000',
    light: '#3B3B3B',
  },
  white: {
    primary: '#FFFFFF',
  },
  red: {
    primary: '#FF0000',
    dark: '#CC0000',
    light: '#FF4040',
    persian: '#E02020',
  },
  violet: {
    primary: '#C603CC',
    eletric: '#6236FF',
  },
  rose: {
    primary: '#EF3E63',
  },
  purple: {
    primary: '#752CD0',
  },
  orange: {
    amber: '#FA6400',
  },
}

export const { blue, gray, yellow, green, orange, black, white, red, violet, rose, purple } = colors
