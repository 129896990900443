import React from 'react'
import QRCode from 'react-qr-code'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { history } from '../../App'
import UmeLinks from '../../common/UmeLinks'
import { Loading } from '../../molecules/Budget/Loading'
import { ArrowBackButton } from '../../molecules/OperatorServiceCenter/ArrowBackButton'
import { BoldText, Card, CardContainer, CardHeader, CardSectionInfo } from '../../molecules/OperatorServiceCenter/CardBorrower'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import { OPERATOR_SERVICE_CENTER_PAGES } from '../../pages/OperatorServiceCenter/enums/operator-service-center-pages.enum'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../services/bff'
import { black, gray, green } from '../../ui/utils/_colors'
import DocumentUtils from '../../utils/document.utils'
import PhoneUtils from '../../utils/phone.utils'
import { BackButton, ConfirmButton, Footer, MainContainer } from './OperatorServiceCenterDocumentVerification'

export const OperatorServiceCenterBorrowerReevaluation: React.FC = () => {
  const { borrowerData, isReevaluatingBorrower } = useTypedSelector(state => ({
    borrowerData: state.operatorServiceCenter.borrower,
    isReevaluatingBorrower: state.operatorServiceCenter.isReevaluatingBorrower,
  }))

  const store = bffParceirosApiService.coordinator.getStore()

  const dispatch = useDispatch()

  const handleConfirmButton = async () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.reevaluateBorrower({ document: borrowerData?.borrower.document!!, borrowerId: borrowerData?.borrower.id!!, storeId: store?.id!! }))
  }

  const handleBackButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE))
  }

  const handleBackToHomeButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE))
    history.push(PagesPathEnum.HOME_PAGE)
  }

  return (
    <>
      <ArrowBackButton onBackButtonClick={handleBackButton} />
      <MainContainer>
        <Card>
          <CardContainer>
            <CardHeader>
              <Title color={green.dark1}>Reavaliação de cadastro disponível</Title>
            </CardHeader>
            <CardSectionInfo>
              <Title color={black.primary}>{borrowerData?.borrower?.name}</Title>
            </CardSectionInfo>
            <CardSectionInfo>
              <BoldText>CPF</BoldText>
              <BoldText color={gray.text}>{DocumentUtils.maskCpf(borrowerData?.borrower?.document)}</BoldText>
            </CardSectionInfo>
            <CardSectionInfo>
              <BoldText>Telefone</BoldText>
              <BoldText color={gray.text}>{PhoneUtils.maskPhone(borrowerData?.borrower?.phoneNumber)}</BoldText>
            </CardSectionInfo>
            <TextContainer>
              <Text>
                Seu telefone mudou? Solicite a alteração pelo atendimento nesse link:{' '}
                <Link href="https://customers.ume.com.br/atualizacao-dados" target="_blank" rel="noopener noreferrer">
                  https://customers.ume.com.br/atualizacao-dados
                </Link>
              </Text>
            </TextContainer>
            <QRCodeContainer>
              <QRCode style={{ height: '100%', width: '40%' }} value={UmeLinks.UPDATE_BORROWER_DATA} />
            </QRCodeContainer>
          </CardContainer>
        </Card>
      </MainContainer>

      <Footer>
        <ConfirmButton disabled={isReevaluatingBorrower} onClick={handleConfirmButton}>
          Reavaliar cadastro
        </ConfirmButton>
        <BackButton onClick={handleBackToHomeButton}>Sair</BackButton>
      </Footer>

      {isReevaluatingBorrower && <Loading loadingText={'Aguarde, essa avaliação pode demorar um pouco. Permaneça na tela...'} />}
    </>
  )
}

interface ColorProps {
  color: string
}

const Title = styled.p<ColorProps>`
  font-family: Roboto;
  font-size: 21px;
  font-weight: bold;
  color: ${(props: ColorProps) => props.color};
  text-align: left;
`

const Link = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const TextContainer = styled.div`
  padding: 10px 0px;
`

const Text = styled.text`
  font-family: Roboto;
  font-size: 16px;
  color: ${black.primary};
  text-align: left;
`
const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1vh;
`
