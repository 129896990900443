import React from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../cash-register/common/formatBRL'
import { ReactComponent as StarIcon } from '../../common/assets/images/star-ume.svg'
import { isDateBeforeToday } from '../../common/date-utils'
import { DateFormat, formatDate, TimeZone } from '../../common/FormatDate'
import { CouponDto } from '../../services/bff/coupons/dto/Coupons.dto'

export interface CouponDetailsProps {
  coupon: CouponDto
  showStoreAlert: boolean
}

const CouponDetails = (props: CouponDetailsProps) => {
  const { coupon, showStoreAlert } = props

  const couponDiscountValue = coupon.percentValue
    ? `${(coupon.percentValue * 100).toFixed(2)}% de desconto`
    : formatBRL(coupon.monetaryValue!)

  const couponValidUntilDate = formatDate(coupon?.validUntil!, DateFormat.BR_DATE_FORMAT_WITH_HOUR, TimeZone.SAO_PAULO)

  const couponValidity =
    coupon.validityInDays === 0
      ? `até hoje às 23:59`
      : `por ${coupon.validityInDays} dia(s), expira em ${couponValidUntilDate} (Horário de Brasília)`

  const wasCouponPreviouslyGenerated = isDateBeforeToday(coupon.createdOn)

  return (
    <Card>
      <StarIcon />
      <>
        <div style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 700, fontSize: '1.2rem' }}>
          Este cliente ganhou um cupom de
        </div>

        <DiscountValue>{couponDiscountValue}</DiscountValue>

        <CouponRulesTitle>Informe as regras do cupom ao cliente:</CouponRulesTitle>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {wasCouponPreviouslyGenerated ? (
            <CouponRulesItem>
              - O prazo máximo para utilização do desconto é até{' '}
              <strong>{couponValidUntilDate} (Horário de Brasília)</strong>
            </CouponRulesItem>
          ) : (
            <CouponRulesItem>
              - Cupom válido <strong>{couponValidity}</strong>
            </CouponRulesItem>
          )}
          <CouponRulesItem>
            - O valor mínimo para compra é de <strong>{formatBRL(coupon.minimumPurchaseValue)}</strong>
          </CouponRulesItem>
          {coupon.maximumDiscountValue ? (
            <CouponRulesItem>
              - O máximo de desconto à ser concedido é de <strong>{formatBRL(coupon.maximumDiscountValue)}</strong>
            </CouponRulesItem>
          ) : null}
          {showStoreAlert ? (
            <CouponRulesItem>- Cupom não é válido para as lojas da Estação dos Cosméticos</CouponRulesItem>
          ) : null}
        </div>
      </>
    </Card>
  )
}

export const Card = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  border-radius: 16px;
  border: 1px solid #f2f2f2;
  background: #ffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #E3E3E3;
  padding: 1rem;
}
`

const DiscountValue = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
  /* identical to box height, or 120% */

  text-align: center;
  letter-spacing: 0.15px;

  color: #11b048;
`

const CouponRulesTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.15px;
  color: #454b46;
`

const CouponRulesItem = styled.div`
  display: inline-block;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.4rem;
  font-size: 0.6rem;
`

const couponContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '0.5rem',
  alignItems: 'center',
  alignSelf: 'center',
  border: '1px solid #E3E3E3',
  borderRadius: '8px',
  padding: '1rem',
}

export default CouponDetails
