export default class DocumentUtils {
  static CPF_SIZE_WITHOUT_DIGITS = 11

  static maskCpf(cpf?: string): string {
    if (!cpf) return ''
    cpf = cpf.replace(/\D/g, '')
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2')
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2')
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    return cpf
  }
}
