import moment from 'moment-timezone'

/**
 * @param date base date to be compared
 * @returns boolean if compared date is on the same day or after base date
 */
export const isDateBeforeToday = (date: string): boolean => {
  const today = moment()
  const mComparedDate = moment(date)

  return mComparedDate.isBefore(today, 'day')
}
