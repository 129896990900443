import PersonIcon from '@material-ui/icons/Person'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Carousel as BootstrapCarousel } from 'react-bootstrap'
import { NumberFormatValues } from 'react-number-format'
import styled from 'styled-components'
import { history, toaster } from '../../App'
import { formatBRL } from '../../cash-register/common/formatBRL'
import { validate_cpf } from '../../common/Validators'
import NumberField from '../../molecules/Application/NumberField'
import { CpfInputForm } from '../../molecules/Inputs/CpfInput'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import { bffParceirosApiService } from '../../services/bff'
import { IStoreResponse } from '../../services/bff/coordinator/dto/Stores.dto'
import { CreateTransactionalCreditEvaluationReq } from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { blue, green, white } from '../../ui/utils/_colors'

enum Step {
  DOCUMENT = 0,
  REQUESTED_AMOUNT = 1,
}

interface TransactionalCreditFormProps {
  document?: string
  onSubmit: (request: CreateTransactionalCreditEvaluationReq) => void
}

const MINIMUM_AMOUNT = 400
const MAXIMUM_AMOUNT = 4000

const amountErrorMessage = `Digite um valor entre ${formatBRL(MINIMUM_AMOUNT)} e ${formatBRL(MAXIMUM_AMOUNT)}.`
const documentErrorMessage = 'Digite um CPF válido.'

const TransactionalCreditForm: React.FC<TransactionalCreditFormProps> = (props: TransactionalCreditFormProps) => {
  const { onSubmit } = props

  const [currentStep, setStep] = useState<number>(Step.DOCUMENT)
  const [amount, setAmount] = React.useState<number | undefined>(undefined)
  const [document, setDocument] = useState<string>(props.document ?? '')
  const [store, setLocalStorageStore] = useState<IStoreResponse>()

  const loadConfiguredStore = useCallback(async () => {
    const store = await bffParceirosApiService.coordinator.getStore()
    setLocalStorageStore(store)
  }, [])

  useEffect(() => {
    loadConfiguredStore()
  }, [store])

  const handleDocumentChange = (values: NumberFormatValues) => {
    setDocument(values.value)
  }

  const handleAmountChange = (values: NumberFormatValues) => {
    setAmount(Number(values.value))
  }

  const isValidDocument = validate_cpf(document)
  const isValidAmount = !!amount && amount >= MINIMUM_AMOUNT && amount <= MAXIMUM_AMOUNT

  const handleConfirmButton = () => {
    if (!store) {
      return toaster.showErrorToast(`Por favor configure uma loja.`)
    }

    if (currentStep == Step.DOCUMENT) {
      if (!isValidDocument) {
        return toaster.showErrorToast(amountErrorMessage)
      }
      setStep(Step.REQUESTED_AMOUNT)
      return
    }

    if (currentStep == Step.REQUESTED_AMOUNT) {
      if (!isValidAmount) {
        return toaster.showErrorToast(documentErrorMessage)
      }
    }

    onSubmit({
      document: document,
      requestedAmount: amount!!,
      storeId: store?.id,
    })
  }

  const canGoNextStep = (): boolean => {
    return (currentStep == Step.DOCUMENT && isValidDocument) || (currentStep == Step.REQUESTED_AMOUNT && isValidAmount)
  }

  const handleReturnToHomeButton = () => {
    return history.push(PagesPathEnum.HOME_PAGE)
  }

  const handleRedoButton = () => {
    setStep(Step.DOCUMENT)
    setAmount(undefined)
    setDocument('')
  }

  return (
    <>
      <FormAndTopWrapper>
        {!store && <WarningHeader>Loja não configurada</WarningHeader>}

        <RedoButtonContainer>
          <RedoButton onClick={handleRedoButton}>Reiniciar</RedoButton>
        </RedoButtonContainer>

        <Carousel activeIndex={currentStep}>
          <Carousel.Item>
            <CpfInputForm document={document} onValueChange={handleDocumentChange} icon={PersonIcon} />
          </Carousel.Item>
          <Carousel.Item>
            <Title>Insira o valor para prosseguir</Title>
            <FormField
              label={'Valor'}
              value={amount}
              placeholder="R$ 1.000,00"
              prefix="R$ "
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              onValueChange={handleAmountChange}
              error={!!amount && !isValidAmount}
              isValidated={isValidDocument}
              helperText={!isValidAmount ? amountErrorMessage : ''}
            />
          </Carousel.Item>
        </Carousel>
      </FormAndTopWrapper>

      <Footer>
        <ConfirmButton disabled={!canGoNextStep()} onClick={handleConfirmButton}>
          Continuar
        </ConfirmButton>
        <BackButton onClick={handleReturnToHomeButton}>Voltar para tela inicial</BackButton>
      </Footer>
    </>
  )
}

const Carousel = styled(BootstrapCarousel).attrs({
  interval: null,
  controls: false,
  slide: false,
})`
  flex-grow: 1;
  justify-content: space-between;
`

const FormField = styled(NumberField).attrs({
  type: 'tel',
  mask: '_',
  style: { width: '100%', marginTop: '25%' },
  autoFocus: true,
})``

const WarningHeader = styled.div`
  width: 100%;
  background-color: #fa6400;
  color: #fff;
  padding: 5px;
  font-family: Roboto;
  letter-spacing: 0.3px;
`

const Title = styled.text`
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  color: black;
  text-align: left;
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100% - 56px);
  width: 100%;
  padding: 10px 0px;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
`

export const ConfirmButton = styled.button`
  margin-top: 2vh;
  background: ${(props: any) => (props && props.disabled ? '#a9e1bc' : '#12AA6A')};
  border-radius: 10px;
  border: 0px;
  padding: 12px;
  height: 60px;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
`

export const BackButton = styled.button`
  margin-top: 2vh;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid ${green.dark1};
  padding: 12px;
  height: 60px;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${green.dark1};
`

const FormAndTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 200px;
`

export const RedoButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  text-align: end;
`

export const RedoButton = styled.p`
  color: ${blue.dark};
  font-size: 16px;
  font-weight: 400;
  text-align: right;
  text-decoration: underline;
  margin: 10px;
  inline-size: fit-content;
  border: none;
  background-color: ${white.primary};
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:focus {
    outline: ${blue} auto 1px;
  }
  @media only screen and (max-width: 770px) {
    margin: 28 auto;
    text-align: center;
    inline-size: none;
  }
`

export default TransactionalCreditForm
