export enum OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE {
  BORROWER_APPLICATION_DENIED = 'Cliente com cadastro negado anteriormente.',
  BORROWER_APPLICATION_INCOMPLETE = 'Cliente com pendências cadastrais.',
  BORROWER_APPLICATION_UNDER_ANALYSIS = 'Cliente está sendo avaliado.',
  BORROWER_APPLICATION_MANUAL_ANALYSIS = 'Cliente está sendo avaliado manualmente.',
  BORROWER_APPLICATION_PRE_APPROVED = 'Cliente está sendo avaliado.',
  BORROWER_BLOCKED_TO_PURCHASE = 'Cliente bloqueado para compras.',
  BORROWER_DISABLE_TO_PURCHASE = 'Cliente desabilitado para compras.',
  OVERDUE_INSTALLMENTS = 'Cliente está com o limite bloqueado.',
}
