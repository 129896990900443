import React from 'react'
import styled from 'styled-components'

interface IBorrowerData {
  shouldRender: boolean
  title: string
  value: string | number
  valueColor: string
}
interface IBorrower {
  borrowerName?: string
  borrowerData?: IBorrowerData[]
}

const CardBorrower: React.FC<IBorrower> = props => {
  const { borrowerName, borrowerData } = props

  return (
    <Card>
      <CardContainer>
        <CardHeader>
          <Title>{borrowerName}</Title>
        </CardHeader>
        {borrowerData?.map(
          (item, index) =>
            item.shouldRender && (
              <CardSectionInfo>
                <BoldText>{item.title}</BoldText>
                <BoldText color={item.valueColor}>{item.value}</BoldText>
              </CardSectionInfo>
            )
        )}
      </CardContainer>
    </Card>
  )
}

export default CardBorrower

interface ColorProps {
  color: string
}

export const Card = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  border-radius: 16px;
  border: 1px solid #f2f2f2;
  background: #ffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  padding: 10px 16px;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  padding: 16px 0px;
`

export const CardSectionInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 6px 0px;
`

const Title = styled.text`
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  color: black;
  text-align: left;
`

export const BoldText = styled.text<ColorProps>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: ${(props: ColorProps) => props.color};
  text-align: left;
`
