import React from 'react'
import styled from 'styled-components'
import { Loading } from '../../ui/Loading'
import { gray } from '../../ui/utils/_colors'
import { Card, CardContainer, CardHeader } from './CardBorrower'

export interface IOfferCard {
  shouldRender: boolean
  title: string
  highlightSubTitle?: string
  subTitle?: string
  icon: JSX.Element
  iconColor: string
  backgroundColor: string
  onButtonPress: () => void
  isLoading: boolean
}

export interface IOfferCardProps {
  userAvailableProducts: IOfferCard[]
}

const CardOffer: React.FC<IOfferCardProps> = props => {
  const { userAvailableProducts } = props

  return (
    <Card>
      <CardContainer>
        <CardHeader>
          <Title>Oferta de serviços</Title>
          {userAvailableProducts.map(
            (item, index) =>
              item.shouldRender &&
              item.subTitle && (
                <Text color={gray.text}>
                  <BoldText>{item.highlightSubTitle}</BoldText>
                  {item.subTitle}
                </Text>
              )
          )}
        </CardHeader>

        <CardOfferContainer>
          {userAvailableProducts.map(
            (item, index) =>
              (item.shouldRender && item.isLoading && (
                <LoadingContainer>
                  <Loading color="#10A343" height={40} width={40} />
                </LoadingContainer>
              )) ||
              (item.shouldRender && (
                <ButtonContainer>
                  <CardButton onClick={item.onButtonPress} backgroundColor={item.backgroundColor}>
                    <OfferIcon color={item.iconColor}>{item.icon}</OfferIcon>
                    <OfferTitle>{item.title}</OfferTitle>
                  </CardButton>
                </ButtonContainer>
              ))
          )}
        </CardOfferContainer>
      </CardContainer>
    </Card>
  )
}

export default CardOffer

interface ColorProps {
  color: string
}

interface CardProps {
  backgroundColor: string
}

const Title = styled.text`
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: left;
`

const BoldText = styled.text<ColorProps>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: ${(props: ColorProps) => props.color};
  text-align: left;
`

const Text = styled.text<ColorProps>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: ${(props: ColorProps) => props.color};
  text-align: left;
  padding: 10px 0px;
`

const CardOfferContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 8px;
`

const ButtonContainer = styled.div`
  margin: 10px 0px;
`

const CardButton = styled.button<CardProps>`
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 140px;
  padding: 24px 0px;
  border-radius: 16px;
  border: 1px solid #f2f2f2;
  background-color: ${(props: CardProps) => props.backgroundColor};
  align-items: center;
  cursor: pointer;
`

const OfferIcon = styled.span<ColorProps>`
  > div > svg {
    width: 36px;
    height: 36px;
  path {
    fill:  ${(props: ColorProps) => props.color};
  }
`

const OfferTitle = styled.text`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  color: black;
  text-align: center;
  padding-top: 8px;
`
const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 140px;
  padding: 24px 0px;
  border-radius: 16px;
  align-items: center;
  cursor: pointer;
`
