import { formatBRL } from '../../../cash-register/common/formatBRL'
import { formatDate } from '../../../common/FormatDate'
import { OperatorServiceCenterBorrowerResponse } from '../../../services/bff/operatorServiceCenter/dtos/OperatorServiceCenter.dto'
import { gray, green } from '../../../ui/utils/_colors'
import DocumentUtils from '../../../utils/document.utils'
import PhoneUtils from '../../../utils/phone.utils'

export const BorrowerData = (props: OperatorServiceCenterBorrowerResponse) => {
  const { borrower, credit } = props
  const obfuscatedPhoneNumber = PhoneUtils.obfuscatePhoneMask(borrower?.phoneNumber)

  return [
    {
      shouldRender: !!borrower.document,
      title: 'CPF',
      value: DocumentUtils.maskCpf(borrower?.document),
      valueColor: gray.text,
    },
    {
      shouldRender: !!borrower.phoneNumber,
      title: 'Telefone',
      value: PhoneUtils.maskPhone(obfuscatedPhoneNumber),
      valueColor: gray.text,
    },
    {
      shouldRender: !!credit.availableLimit,
      title: 'Limite Ume',
      value: formatBRL(credit.availableLimit || 0),
      valueColor: green.dark1,
    },
    {
      shouldRender: !!credit.averageTicket,
      title: 'Ticket médio',
      value: formatBRL(credit.averageTicket || 0),
      valueColor: gray.text,
    },
    {
      shouldRender: !!credit.lastPurchaseDate,
      title: 'Última compra',
      value: formatDate(credit.lastPurchaseDate || ''),
      valueColor: gray.text,
    },
    {
      shouldRender: true,
      title: 'Incentivo',
      value: credit.incentiveCategory || '-',
      valueColor: gray.text,
    },
  ]
}
