import * as React from 'react'
import styled from 'styled-components'
import FraudDesktopBanner from '../../common/assets/images/fraud-banner-desktop.png'
import FraudMobileBanner from '../../common/assets/images/fraud-banner-mobile.png'
import { mobile } from '../../common/assets/utils/_breakpoints'
export default class BannerWaning extends React.Component {
  public render() {
    return (
      <SectionContainer>
        <BannerImgContainer>
          <MobileImage media={`(max-width: ${mobile})`} onClick={() => {}} srcSet={FraudMobileBanner} />
          <DesktopImage onClick={() => {}} src={FraudDesktopBanner} />
        </BannerImgContainer>
      </SectionContainer>
    )
  }
}

const SectionContainer = styled.div`
  padding: 30px 0px;
  :hover {
    cursor: pointer;
  }
`

const BannerImgContainer = styled.picture``

const MobileImage = styled.source`
  max-width: 90%;
  height: auto;
`

const DesktopImage = styled.img`
  max-width: 90%;
  height: auto;
`
