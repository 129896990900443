import { Button, Card, Colors, Elevation, H4, H5, Icon } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router'
import styled from 'styled-components'
import { resolvePurchaseReceiptRoute } from '../../pages/Receipts/Utils'
import InvoicesSliceReducer from '../../redux/reducers/invoice/invoices.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../services/bff'
import { Loading } from '../../ui'
import { formItemsContainerStyle } from '../styles'

const spinnerContainerOverrideStyle = () => {
  const styles = {
    ...formItemsContainerStyle,
    width: '60vw',
    height: '55vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#0D8050',
    padding: '30px',
  }
  if (window.matchMedia('(max-width: 768px)').matches) {
    styles.height = '80vh'
    styles.padding = '20px'
  }
  return styles
}
export interface IApprovedPurchaseProps {
  contractId: string
  borrowerId: string
}

const ApprovedPurchase: React.FC<IApprovedPurchaseProps> = ({ contractId, borrowerId }) => {
  const [redirectToCashierHome, setRedirectToCashierHome] = useState(false)
  const { isCreatingBorrowerNextInvoice } = useTypedSelector(state => ({
    isCreatingBorrowerNextInvoice: state.invoices.isCreatingBorrowerNextInvoice,
  }))
  const dispatch = useDispatch()
  const store = bffParceirosApiService.coordinator.getStore()

  useEffect(() => {
    const contractReceiptUri = resolvePurchaseReceiptRoute(contractId)
    window.open(contractReceiptUri, '_blank')
  }, [])

  const setupRedirect = () => {
    setRedirectToCashierHome(true)
  }

  const createInvoice = () => {
    dispatch(InvoicesSliceReducer.actions.createBorrowerNextInvoice(borrowerId))
  }
  const contractReceiptUri = resolvePurchaseReceiptRoute(contractId)

  if (redirectToCashierHome) {
    return <Redirect to="/caixa" />
  }

  return (
    <Card style={spinnerContainerOverrideStyle()} interactive={false} elevation={Elevation.TWO}>
      <div style={{ padding: '32px' }}>
        <Icon style={{ color: 'white' }} iconSize={75} icon={'tick'} />
      </div>
      <H4 style={{ color: 'white' }}>
        Compra Finalizada! Se o comprovante não abriu automaticamente,{' '}
        <a
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: 'white' }}
          href={contractReceiptUri}
          target="_blank"
          tabIndex={1}
        >
          CLIQUE AQUI
        </a>
        .
      </H4>
      {store?.settings?.enableToGenerateBorrowerInvoice && borrowerId && (
        <CustomButton
          large
          style={{
            marginTop: '32px',
            backgroundColor: Colors.LIGHT_GRAY4,
            backgroundImage: 'none',
          }}
          autoFocus
          icon="barcode"
          onClick={createInvoice}
          tabIndex={1}
          disabled={isCreatingBorrowerNextInvoice}
        >
          {isCreatingBorrowerNextInvoice ? (
            <Loading color="#5C7080" height={20} width={20} />
          ) : (
            <H5 style={{ color: Colors.GRAY1, marginBottom: 0 }}>Imprimir próximo boleto</H5>
          )}
        </CustomButton>
      )}
      <CustomButton
        large
        style={{
          marginTop: '32px',
          backgroundColor: Colors.LIGHT_GRAY4,
          backgroundImage: 'none',
        }}
        autoFocus
        icon="arrow-left"
        onClick={setupRedirect}
        tabIndex={1}
      >
        <H5 style={{ color: Colors.GRAY1, marginBottom: 0 }}>Voltar à página inicial de Caixa</H5>
      </CustomButton>
    </Card>
  )
}

export default ApprovedPurchase

const CustomButton = styled(Button)`
  width: 40%;

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 70%;
  }
`
