import { getCoordinatorApiRootUrl } from "../../config/config"

export const resolvePurchaseReceiptRoute = (id: string): string => {
    return `/render/${id}/originations`
}

export const resolveRenegotiationPaymentReceiptRoute = (ids: string[]): string => {
    return `/render/${JSON.stringify(ids.map(x => parseInt(x)))}/renegotiations-payments`
}

export const resolvePaymentReceiptRoute = (ids: string[]): string => {
    return `/render/${JSON.stringify(ids.map(x => parseInt(x)))}/payments`
}

export const resolveExternalReceiptUrl = (type: string, ids: string): string => {
    const baseUrl = getCoordinatorApiRootUrl()
    return baseUrl + resolveExternalReceiptRoute(type, ids)
}

const resolveExternalReceiptRoute = (type: string, ids: string) => {
    switch (type) {
        case 'originations': return `/contracts/${ids}/render`
        case 'payments': return `/contracts/installments/payments/render?ids=${ids}`
        case 'renegotiations-payments': return `/renegotiations/payments/render?ids=${ids}`
        default:
            return `/contracts/${ids}/render`
    }
}