import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { history } from '../../App'
import { LoadingIcon } from '../../atoms/LoadingIcon/LoadingIcon'
import { ArrowBackButton } from '../../molecules/OperatorServiceCenter/ArrowBackButton'
import CardBorrower from '../../molecules/OperatorServiceCenter/CardBorrower'
import CardOffer from '../../molecules/OperatorServiceCenter/CardOffer'
import TxCreditCard from '../../molecules/OperatorServiceCenter/TxCreditCard'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import TransactionalCreditSliceReducer from '../../redux/reducers/tx-credit/tx-credit.reducer'
import { bffParceirosApiService } from '../../services/bff'
import { TransactionalCreditEvaluationResult } from '../../services/bff/transactional-credit/enums/transactional-credit-evaluation-results.enum'
import { TransactionalCreditEvaluationStatus } from '../../services/bff/transactional-credit/enums/transactional-credit-evaluation-status.enum'
import CouponDetails from './CouponDetails'
import { BorrowerData } from './DataLists/BorrowerData'
import { UserAvailableProducts } from './DataLists/UserAvailableProducts'
import { BackButton, Footer, MainContainer } from './OperatorServiceCenterDocumentVerification'

const OperatorServiceCenterBorrowerData: React.FC = () => {
  const { borrowerData, txCreditEvaluation, isFetchingTransactionalCreditEvaluation } = useTypedSelector(state => ({
    borrowerData: state.operatorServiceCenter.borrower,
    txCreditEvaluation: state.transactionalCredit.transactionalCreditEvaluation,
    isFetchingTransactionalCreditEvaluation: state.transactionalCredit.isFetchingTransactionalCreditEvaluation,
  }))

  const dispatch = useDispatch()

  const store = bffParceirosApiService.coordinator.getStore()

  useEffect(() => {
    return () => {
      dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(
      TransactionalCreditSliceReducer.actions.fetchTransactionalCreditEvaluation({
        cpf: borrowerData!!.borrower.document,
        storeId: store?.id,
        retailerId: store?.retailerId,
        status: TransactionalCreditEvaluationStatus.ACCEPTED,
        results: [TransactionalCreditEvaluationResult.APPROVED, TransactionalCreditEvaluationResult.PARTIALLY_APPROVED],
      })
    )
  }, [])

  const handleBackButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
  }

  const handleBackToHomeButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
    history.push(PagesPathEnum.HOME_PAGE)
  }

  return (
    <>
      <ArrowBackButton onBackButtonClick={handleBackButton} />
      <MainContainer>
        <CardBorrower borrowerName={borrowerData?.borrower?.name} borrowerData={BorrowerData(borrowerData!)} />

        {isFetchingTransactionalCreditEvaluation && <LoadingIcon />}
        {!isFetchingTransactionalCreditEvaluation && txCreditEvaluation && (
          <TxCreditCard availableLimit={borrowerData?.credit.availableLimit ?? 0} evaluation={txCreditEvaluation} />
        )}

        {borrowerData?.coupon ? <CouponDetails coupon={borrowerData?.coupon!} showStoreAlert={false} /> : null}

        <CardOffer
          userAvailableProducts={UserAvailableProducts({
            document: borrowerData!.borrower!.document,
            borrowerId: borrowerData?.borrower?.id,
          })}
        />
      </MainContainer>

      <Footer>
        <BackButton onClick={handleBackToHomeButton}>Sair</BackButton>
      </Footer>
    </>
  )
}

export default OperatorServiceCenterBorrowerData
