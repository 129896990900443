import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { OPERATOR_SERVICE_CENTER_PAGES } from '../../../pages/OperatorServiceCenter/enums/operator-service-center-pages.enum'
import { OperatorServiceCenterBorrowerResponse } from '../../../services/bff/operatorServiceCenter/dtos/OperatorServiceCenter.dto'
import { APPLICATION_STATUS, BorrowerStatus } from '../application/application.types'

export type OperatorServiceCenterState = {
  page: OPERATOR_SERVICE_CENTER_PAGES
  isFetchingBorrower: boolean
  borrower?: OperatorServiceCenterBorrowerResponse
  borrowerRestriction?: string
  error?: AxiosError
  applicationReevaluationStatus?: APPLICATION_STATUS
  preApplicationStatus?: BorrowerStatus
  document?: string
  isReevaluatingBorrower: boolean
}

const initialState: OperatorServiceCenterState = {
  page: OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE,
  isFetchingBorrower: false,
  isReevaluatingBorrower: false,
  applicationReevaluationStatus: undefined,
  borrower: undefined,
  borrowerRestriction: undefined,
  error: undefined,
  preApplicationStatus: undefined,
}

export interface IFetchBorrowerPayload {
  document: string
  storeId: string
}

export interface ReevaluateBorrowerPayload {
  document: string
  borrowerId: string
  storeId: string
}

export interface ReevaluatedBorrower {
  borrower: OperatorServiceCenterBorrowerResponse
  reevaluationStatus: APPLICATION_STATUS
}

const OperatorServiceCenterSliceReducer = createSlice({
  name: 'operatorServiceCenter',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<OPERATOR_SERVICE_CENTER_PAGES>) => {
      const page = action.payload
      return { ...state, page }
    },
    setBorrowerRestriction: (state, action: PayloadAction<string>) => {
      const borrowerRestriction = action.payload
      return { ...state, borrowerRestriction }
    },
    setDocument: (state, action: PayloadAction<string>) => {
      return { ...state, document: action.payload }
    },
    fetchBorrower: (state, action: PayloadAction<IFetchBorrowerPayload>) => {
      const { document, storeId } = action.payload
      return {
        ...state,
        document,
        storeId,
        isFetchingBorrower: true,
      }
    },
    fetchBorrowerSuccess: (state, action: PayloadAction<OperatorServiceCenterBorrowerResponse>) => {
      const borrower = action.payload
      return {
        ...state,
        borrower,
        isFetchingBorrower: false,
        preApplicationStatus: action.payload?.application?.status,
      }
    },
    fetchBorrowerError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      return {
        ...state,
        error,
        isFetchingBorrower: false,
      }
    },

    reevaluateBorrower: (state, action: PayloadAction<ReevaluateBorrowerPayload>) => {
      const { borrowerId, storeId } = action.payload
      return {
        ...state,
        borrowerId,
        storeId,
        isReevaluatingBorrower: true,
      }
    },
    reevaluateBorrowerSuccess: (state, action: PayloadAction<ReevaluatedBorrower>) => {
      const { borrower, reevaluationStatus } = action.payload
      return {
        ...state,
        borrower,
        isReevaluatingBorrower: false,
        applicationReevaluationStatus: reevaluationStatus,
      }
    },
    reevaluateBorrowerError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      return {
        ...state,
        error,
        isReevaluatingBorrower: false,
      }
    },
    resetRequest: (state, action: PayloadAction<undefined>) => {
      return {
        ...state,
        page: OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE,
        isFetchingBorrower: false,
        borrower: undefined,
        borrowerRestriction: undefined,
        error: undefined,
        isReevaluatingBorrower: false,
        applicationReevaluationStatus: undefined,
      }
    },
  },
})

export default OperatorServiceCenterSliceReducer
