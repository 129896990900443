import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { NumberFormatValues } from 'react-number-format'
import styled from 'styled-components'
import { history, toaster } from '../../App'
import { validate_cpf } from '../../common/Validators'
import { CpfInputForm } from '../../molecules/Inputs/CpfInput'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import { bffParceirosApiService } from '../../services/bff'
import { IStoreResponse } from '../../services/bff/coordinator/dto/Stores.dto'
import { CreateSmartphoneSaleCreditEvaluationRequest } from '../../services/bff/smartphone-sale/dtos/smartphone-sale-credit-evaluation.dto'
import PhoneLayout from '../../ui/PhoneLayout/PhoneLayout'
import { blue, green, orange, white } from '../../ui/utils/_colors'

interface SmartphoneSaleFormProps {
  document?: string
  onSubmit: (request: CreateSmartphoneSaleCreditEvaluationRequest) => void
}

export const SmartphoneSaleForm: React.FC<SmartphoneSaleFormProps> = (props: SmartphoneSaleFormProps) => {
  const { onSubmit } = props

  const [document, setDocument] = useState<string>(props.document ?? '')
  const [store, setLocalStorageStore] = useState<IStoreResponse>()
  const isValidDocument = validate_cpf(document)

  const loadConfiguredStore = useCallback(async () => {
    const store = await bffParceirosApiService.coordinator.getStore()
    setLocalStorageStore(store)
  }, [])

  useEffect(() => {
    loadConfiguredStore()
  }, [store])

  const handleDocumentChange = (values: NumberFormatValues) => {
    setDocument(values.value)
  }

  const handleConfirmButton = () => {
    if (!store) {
      return toaster.showErrorToast(`Por favor configure uma loja.`)
    }

    onSubmit({
      document: document,
      storeId: store?.id,
      retailerId: store?.retailerId,
    })
  }

  const canGoNextStep = (): boolean => {
    return isValidDocument
  }

  const handleReturnToHomeButton = () => {
    return history.push(PagesPathEnum.HOME_PAGE)
  }

  return (
    <Container>
      <FormAndTopWrapper>
        {!store && <WarningHeader>Loja não configurada</WarningHeader>}

        <CpfInputForm document={document} onValueChange={handleDocumentChange} />
      </FormAndTopWrapper>

      <Footer>
        <ConfirmButton disabled={!canGoNextStep()} onClick={handleConfirmButton}>
          Continuar
        </ConfirmButton>
        <BackButton onClick={handleReturnToHomeButton}>Voltar para tela inicial</BackButton>
      </Footer>
    </Container>
  )
}

const WarningHeader = styled.div`
  width: 100%;
  background-color: ${orange.amber};
  color: ${white.primary};
  padding: 5px;
  font-family: Roboto;
  letter-spacing: 0.3px;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
`

export const ConfirmButton = styled.button`
  margin-top: 2vh;
  background: ${(props: any) => (props && props.disabled ? green.light3 : green.dark1)};
  border-radius: 10px;
  border: 0px;
  padding: 12px;
  height: 48px;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${white.primary};
`

export const BackButton = styled.button`
  margin-top: 2vh;
  background: ${white.primary};
  border-radius: 10px;
  border: 1px solid ${green.dark1};
  padding: 12px;
  height: 48px;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${green.dark1};
`

const FormAndTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 200px;
`

export const RedoButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  text-align: end;
`

export const RedoButton = styled.p`
  color: ${blue.dark};
  font-size: 16px;
  font-weight: 400;
  text-align: right;
  text-decoration: underline;
  margin: 10px;
  inline-size: fit-content;
  border: none;
  background-color: ${white.primary};
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:focus {
    outline: ${blue} auto 1px;
  }
  @media only screen and (max-width: 770px) {
    margin: 28 auto;
    text-align: center;
    inline-size: none;
  }
`

const Container = styled(PhoneLayout).attrs({
  style: {
    justifyContent: 'space-between',
    padding: 24,
  },
})``
