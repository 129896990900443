// TODO: add all paths as enum to replace the paths string for PagesPathEnum
export enum PagesPathEnum {
  HOME_PAGE = '/',
  BUDGET_PAGE = '/calculadora',
  TRANSACTIONAL_CREDIT_PAGE = '/super-compra',
  APPLICATION = '/applications/:cpf?',
  CASH_REGISTER = '/caixa',
  PURCHASE = '/caixa/compras',
  OPERATOR_SERVICE_CENTER = '/central-de-vendas',
}
