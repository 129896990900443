import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BackIcon } from '../../common/assets/images/arrow-left.svg'
import { gray } from '../../ui/utils/_colors'

interface Props {
  onBackButtonClick?: React.MouseEventHandler<HTMLElement>
}

export const ArrowBackButton = (props: Props): JSX.Element => {
  return (
    <Header>
      <BackButton onClick={props.onBackButtonClick}>
        <StyledBackIcon />
      </BackButton>
    </Header>
  )
}

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
`

export const BackButton = styled.button`
  width: 44px;
  height: 44px;
  border: 1px solid #f2f2f2;
  border-radius: 50%;
  background-color: #ffff;
  color: ${gray.text};
  text-align: 
  cursor: pointer; 
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
`

const StyledBackIcon = styled(BackIcon)`
  width: 8px;
  height: auto;
  path {
    fill: ${gray.text};
  }
`
