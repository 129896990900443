import moment from 'moment-timezone'

export const enum DateFormat {
  BR_DATE_FORMAT = 'DD/MM/YYYY',
  BR_DATE_FORMAT_WITH_HOUR = 'DD/MM/YYYY HH:mm',
}

export enum TimeZone {
  MANAUS = 'America/Manaus',
  SAO_PAULO = 'America/Sao_Paulo',
}

export const formatDate = (el: string, format: DateFormat = DateFormat.BR_DATE_FORMAT, timeZone?: TimeZone): string => {
  if (timeZone) {
    return moment(el)
      .tz(timeZone)
      .format(format)
  }
  return moment(new Date(el))
    .utc()
    .format(format)
}

export const formatNullableDate = (
  dateIso?: string,
  format: DateFormat = DateFormat.BR_DATE_FORMAT,
  timeZone?: TimeZone
): string => {
  if (!dateIso) return '-'
  return formatDate(dateIso, format, timeZone)
}