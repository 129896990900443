import { Button, Dialog, H5, Icon, Intent, ProgressBar } from '@blueprintjs/core'
import { CommunicationChannelLabel as CommunicationChannel } from '@scudraservicos/coordinator-client/dist/src/services/applications/interfaces/CommunicationChannel.types'
import React, { RefObject } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import styled from 'styled-components'
import { formatBRL } from '../../cash-register/common/formatBRL'
import { formatCellphoneWithoutNinthDigit, formatDDGPhoneNumber } from '../../cash-register/common/formatCellPhone'
import { PhoneVerificationStep } from '../../cash-register/purchases-old/Purchases'
import { cellPhoneFormGroupStyle, formActionsStyle, formItemStyle } from '../../cash-register/styles'
import { UME_PHONES } from '../../config/config'
import { RETRY_SMS_COUNTDOWN_SECONDS } from '../../constants'
import { SourceProduct } from '../../services/bff/coordinator/dto/Contracts.dto'
import { IPhoneCommunicationChannel } from '../../services/bff/originations/dto/PhoneVerificationRecord.dto'
import { isImeiValid } from '../../utils/imei.utils'
import { PurchaseConditions } from './PurchaseInstallmentsOptionsCard'

interface Props {
  purchaseConditions: PurchaseConditions[]
  selectedPurchaseCondition: number
  isVerificationDialogOpen: boolean
  isPurchaseConfirmed: boolean
  authorizationStrategy: string
  formattedCellphone: string
  verificationCode: string
  phoneVerificationStep: PhoneVerificationStep
  countDownSeconds: number
  isCountDownRunning: boolean
  cellphone: string
  startVerificationProcess: () => void
  handleKeyPressCancel: (event: React.KeyboardEvent) => void
  confirmPurchaseButtonRef: RefObject<HTMLButtonElement>
  cancelPurchase: () => void
  smsCodeInputRef: RefObject<HTMLInputElement>
  handleKeyPressPhoneVerification: (event: React.KeyboardEvent) => void
  onVerificationCodeChange: (values: NumberFormatValues) => void
  onSendVerificationCodeClick: () => void
  askForVerfication: (proposalId: string, communicationChannel?: IPhoneCommunicationChannel) => void
  finishPurchase: () => void
  smartphoneIMEI: string
  handleKeyPressSmartphoneIMEIVerification: (event: React.KeyboardEvent) => void
  onSmartphoneIMEIChange: (values: NumberFormatValues) => void
  needSendSmartphoneImei: boolean
  sourceProduct?: string
  isPurchaseConfirmationLoading: boolean
}

export const PurchaseConfirmationPopup: React.FC<Props> = props => {
  const {
    purchaseConditions,
    selectedPurchaseCondition,
    isVerificationDialogOpen,
    isPurchaseConfirmed,
    authorizationStrategy,
    formattedCellphone,
    verificationCode,
    phoneVerificationStep,
    countDownSeconds,
    isCountDownRunning,
    cellphone,
    startVerificationProcess,
    handleKeyPressCancel,
    confirmPurchaseButtonRef,
    cancelPurchase,
    smsCodeInputRef,
    handleKeyPressPhoneVerification,
    onVerificationCodeChange,
    onSendVerificationCodeClick,
    askForVerfication,
    finishPurchase,
    smartphoneIMEI,
    handleKeyPressSmartphoneIMEIVerification,
    onSmartphoneIMEIChange,
    needSendSmartphoneImei,
    sourceProduct,
    isPurchaseConfirmationLoading,
  } = props

  return (
    <div style={{ ...formActionsStyle }}>
      <Dialog isOpen={isVerificationDialogOpen} style={{ paddingBottom: 0 }}>
        {!isPurchaseConfirmed && selectedPurchaseCondition !== -1 && (
          <div
            style={{
              padding: '32px',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            onKeyDown={(event: React.KeyboardEvent) => handleKeyPressCancel(event)}
          >
            <H5>
              {'Confirmar compra com total de '}
              {formatBRL(
                purchaseConditions[selectedPurchaseCondition].installments *
                  purchaseConditions[selectedPurchaseCondition].installmentValue
              )}
              {' em '}
              {purchaseConditions[selectedPurchaseCondition].installments} {'parcelas de '}
              {formatBRL(purchaseConditions[selectedPurchaseCondition].installmentValue)} ?
            </H5>
            {sourceProduct == SourceProduct.SMARTPHONE_SALE ? (
              <>
                <Label>Informe o IMEI para confirmar</Label>
                <NumberFormat
                  style={formItemStyle}
                  id="imei-code-input"
                  className={`bp3-input ${
                    smartphoneIMEI && !isImeiValid(smartphoneIMEI) ? 'bp3-intent-danger' : 'bp3-intent-none'
                  } `}
                  placeholder="Número IMEI do aparelho"
                  autoComplete="off"
                  tabIndex={1}
                  mask="_"
                  value={smartphoneIMEI}
                  onKeyDown={(event: React.KeyboardEvent) => handleKeyPressSmartphoneIMEIVerification(event)}
                  onValueChange={(values: NumberFormatValues) => onSmartphoneIMEIChange(values)}
                />
              </>
            ) : (
              undefined
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                type="button"
                className="bp3-button bp3-intent-success"
                onClick={() => startVerificationProcess()}
                tabIndex={1}
                elementRef={confirmPurchaseButtonRef}
                autoFocus={isVerificationDialogOpen && !isPurchaseConfirmed && !needSendSmartphoneImei}
                style={{
                  marginRight: '32px',
                  marginTop: '16px',
                }}
                loading={isPurchaseConfirmationLoading}
              >
                Sim
                <Icon
                  style={{
                    marginRight: '2px',
                    marginLeft: '8px',
                  }}
                  icon="tick"
                />
              </Button>
              <Button
                style={{
                  marginRight: '16px',
                  marginTop: '16px',
                }}
                rightIcon="cross"
                intent="danger"
                text="Não"
                tabIndex={1}
                onClick={() => cancelPurchase()}
              />
            </div>
          </div>
        )}
        {isPurchaseConfirmed && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '32px',
              justifyContent: 'flex-end',
            }}
            onKeyDown={(event: React.KeyboardEvent) => handleKeyPressCancel(event)}
          >
            <H5>
              {authorizationStrategy === 'ANALYST_VERIFICATION' ? (
                <div>
                  Estamos avaliando sua compra.
                  <br />
                  <br />
                  Em instantes um atendente UME entrará em contato pelo número {formattedCellphone}.
                  <br />
                  <br />
                  Dúvidas? Fale conosco:
                  <br />
                  <br />
                  {formatDDGPhoneNumber(UME_PHONES.customerService.phoneNumber)} ou WhatsApp:{' '}
                  {formatCellphoneWithoutNinthDigit(UME_PHONES.customerService.whatsapp)}
                </div>
              ) : (
                `Digite o código de verificação enviado para o número ${formattedCellphone} para concluir a compra.`
              )}
            </H5>
            <div style={cellPhoneFormGroupStyle}>
              <div>
                <NumberFormat
                  style={formItemStyle}
                  id="sms-code-input"
                  className={`bp3-input ${
                    verificationCode && verificationCode.length < 6 ? 'bp3-intent-danger' : 'bp3-intent-none'
                  } `}
                  placeholder="Código de Verificação"
                  autoComplete="off"
                  getInputRef={smsCodeInputRef}
                  tabIndex={1}
                  format="### ###"
                  mask="_"
                  value={verificationCode}
                  disabled={phoneVerificationStep === PhoneVerificationStep.Verified}
                  onKeyDown={(event: React.KeyboardEvent) => handleKeyPressPhoneVerification(event)}
                  onValueChange={(values: NumberFormatValues) => onVerificationCodeChange(values)}
                />

                <Button
                  style={{ ...formItemStyle, marginLeft: '16px', width: '12em' }}
                  intent="primary"
                  tabIndex={1}
                  text={
                    (phoneVerificationStep === PhoneVerificationStep.CodeReady ||
                      phoneVerificationStep === PhoneVerificationStep.SendingCode) &&
                    'Checar código'
                  }
                  loading={
                    phoneVerificationStep === PhoneVerificationStep.WaitingForVerification ||
                    phoneVerificationStep === PhoneVerificationStep.SendingCode
                  }
                  onClick={() => onSendVerificationCodeClick()}
                  disabled={phoneVerificationStep === PhoneVerificationStep.Verified}
                >
                  {phoneVerificationStep === PhoneVerificationStep.Verified && <Icon icon="tick" />}
                </Button>
              </div>
            </div>
            {phoneVerificationStep !== PhoneVerificationStep.Verified &&
              authorizationStrategy !== 'ANALYST_VERIFICATION' && (
                <div
                  style={{
                    marginTop: '1.5em',
                  }}
                >
                  <p>
                    {'O código pode ser reenviado novamente após '}
                    {RETRY_SMS_COUNTDOWN_SECONDS} segundos
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}
                  >
                    <ProgressBar
                      intent={Intent.SUCCESS}
                      value={(RETRY_SMS_COUNTDOWN_SECONDS - countDownSeconds) / RETRY_SMS_COUNTDOWN_SECONDS}
                      animate={true}
                      stripes={false}
                    />
                    <span style={{ marginTop: '0.5em' }}>{countDownSeconds}</span>
                  </div>

                  <Button
                    text="SMS"
                    intent="primary"
                    autoFocus={!isCountDownRunning}
                    onClick={() => askForVerfication(cellphone, CommunicationChannel.SMS)}
                    tabIndex={1}
                    style={{ marginBottom: '4px' }}
                    type="submit"
                    disabled={isCountDownRunning || phoneVerificationStep === PhoneVerificationStep.SendingCode}
                  />
                  <Button
                    text="Whatsapp"
                    intent="primary"
                    autoFocus={!isCountDownRunning}
                    onClick={() => askForVerfication(verificationCode, CommunicationChannel.WHATSAPP)}
                    tabIndex={1}
                    style={{ marginBottom: '4px', marginLeft: '8px' }}
                    type="submit"
                    disabled={isCountDownRunning || phoneVerificationStep === PhoneVerificationStep.SendingCode}
                  />
                  <Button
                    text="Chamada telefônica"
                    intent="primary"
                    autoFocus={!isCountDownRunning}
                    onClick={() => askForVerfication(verificationCode, CommunicationChannel.PHONE_CALL)}
                    tabIndex={1}
                    style={{ marginBottom: '4px', marginLeft: '8px' }}
                    type="submit"
                    disabled={isCountDownRunning || phoneVerificationStep === PhoneVerificationStep.SendingCode}
                  />
                </div>
              )}
            <div
              style={{
                marginTop: '16px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                id="button_finish_purchase"
                style={{
                  marginRight: '32px',
                  marginTop: '16px',
                }}
                rightIcon="tick"
                disabled={phoneVerificationStep !== PhoneVerificationStep.Verified}
                intent="success"
                text="Finalizar"
                autoFocus={phoneVerificationStep === PhoneVerificationStep.Verified}
                tabIndex={1}
                onClick={() => finishPurchase()}
              />

              <Button
                style={{
                  marginTop: '16px',
                }}
                rightIcon="cross"
                intent="danger"
                text="Cancelar"
                tabIndex={1}
                onClick={() => cancelPurchase()}
              />
            </div>
          </div>
        )}
      </Dialog>
    </div>
  )
}

const Label = styled.p`
  color: #182026;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`

export default PurchaseConfirmationPopup
