export default class PhoneUtils {
  static PHONE_NUMBER_LENGTH_WITH_PREFIX = 14

  static maskPhone(phoneNumber?: string): string {
    if (!phoneNumber || phoneNumber.length < this.PHONE_NUMBER_LENGTH_WITH_PREFIX) return ''

    return `(${phoneNumber.slice(3, 5)}) ${phoneNumber.slice(5, 10)}-${phoneNumber.slice(10, 14)}`
  }

  static obfuscatePhoneMask(phoneNumber?: string): string {
    if (!phoneNumber || phoneNumber.length < this.PHONE_NUMBER_LENGTH_WITH_PREFIX) return ''

    const phoneStart = phoneNumber.slice(0, 6)
    const phoneEnd = phoneNumber.slice(10, 14)

    return `${phoneStart}****${phoneEnd}`
  }
}
