import PersonIcon from '@material-ui/icons/Person'
import React, { useCallback, useEffect, useState } from 'react'
import { NumberFormatValues } from 'react-number-format'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { history, toaster } from '../../App'
import { validate_cpf } from '../../common/Validators'
import NumberField from '../../molecules/Application/NumberField'
import { Loading } from '../../molecules/Budget'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../services/bff'
import { IStoreResponse } from '../../services/bff/coordinator/dto/Stores.dto'
import { green } from '../../ui/utils/_colors'
import DocumentUtils from '../../utils/document.utils'

const OperatorServiceCenterDocumentVerification: React.FC = () => {
  const { isFetchingBorrower, fetchBorrowerError } = useTypedSelector(state => ({
    isFetchingBorrower: state.operatorServiceCenter.isFetchingBorrower,
    fetchBorrowerError: state.operatorServiceCenter.error,
  }))

  const dispatch = useDispatch()

  const [formDocument, setFormDocument] = useState<string>('')
  const [localStorageStore, setLocalStorageStore] = useState<IStoreResponse>()

  const loadConfiguredStore = useCallback(async () => {
    const store = await bffParceirosApiService.coordinator.getStore()
    setLocalStorageStore(store)
  }, [])

  useEffect(() => {
    loadConfiguredStore()
  }, [localStorageStore])

  const handleDocumentChange = (values: NumberFormatValues) => {
    setFormDocument(values.value)
  }

  const handleConfirmButton = () => {
    if (!localStorageStore) {
      return toaster.showErrorToast(`Por favor configure uma loja.`)
    }

    if (!validate_cpf(formDocument)) {
      return toaster.showErrorToast(`Preencha um CPF válido.`)
    }

    dispatch(
      OperatorServiceCenterSliceReducer.actions.fetchBorrower({ document: formDocument, storeId: localStorageStore.id })
    )
  }

  const handleBackButton = () => {
    return history.push(PagesPathEnum.HOME_PAGE)
  }

  const isDocumentValidated = formDocument.length === DocumentUtils.CPF_SIZE_WITHOUT_DIGITS

  return (
    <>
      <MainContainer>
        {!localStorageStore && <WarningHeader>Loja não configurada</WarningHeader>}
        {isFetchingBorrower && <Loading />}

        <Title>Insira o CPF do cliente para prosseguir</Title>
        <NumberField
          autoFocus
          Icon={PersonIcon}
          label="CPF"
          value={formDocument}
          type="tel"
          format="###.###.###-##"
          mask="_"
          placeholder="000.000.000-00"
          onValueChange={handleDocumentChange}
          isValidated={isDocumentValidated}
          error={formDocument.length > 0 && !isDocumentValidated}
          style={{ marginTop: '25%' }}
        />
      </MainContainer>

      <Footer>
        <ConfirmButton disabled={!isDocumentValidated || !localStorageStore} onClick={handleConfirmButton}>
          Continuar
        </ConfirmButton>
        <BackButton onClick={handleBackButton}>Voltar para tela inicial</BackButton>
      </Footer>
    </>
  )
}

export default OperatorServiceCenterDocumentVerification

const WarningHeader = styled.div`
  width: 100%;
  background-color: #fa6400;
  color: #fff;
  padding: 5px;
  font-family: Roboto;
  letter-spacing: 0.3px;
`

const Title = styled.text`
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  color: black;
  text-align: left;
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100% - 56px);
  width: 100%;
  padding: 10px 0px;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
`

export const ConfirmButton = styled.button`
  margin-top: 2vh;
  background: ${(props: any) => (props && props.disabled ? '#a9e1bc' : '#12AA6A')};
  border-radius: 10px;
  border: 0px;
  padding: 12px;
  height: 60px;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
`

export const BackButton = styled.button`
  margin-top: 2vh;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid ${green.dark1};
  padding: 12px;
  height: 60px;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${green.dark1};
`
