import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { history } from '../../App'
import { formatCellphoneWithoutNinthDigit, formatDDGPhoneNumber } from '../../cash-register/common/formatCellPhone'
import { ArrowBackButton } from '../../molecules/OperatorServiceCenter/ArrowBackButton'
import {
  BoldText,
  Card,
  CardContainer,
  CardHeader,
  CardSectionInfo,
} from '../../molecules/OperatorServiceCenter/CardBorrower'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import { OPERATOR_SERVICE_CENTER_PAGES } from '../../pages/OperatorServiceCenter/enums/operator-service-center-pages.enum'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { black, gray, red } from '../../ui/utils/_colors'
import DocumentUtils from '../../utils/document.utils'
import UmeConfigUtils from '../../utils/ume-config.utils'
import { BackButton, Footer, MainContainer } from './OperatorServiceCenterDocumentVerification'

const OperatorServiceCenterBorrowerRestriction: React.FC = () => {
  const { borrower, borrowerRestriction } = useTypedSelector(state => ({
    borrower: state.operatorServiceCenter.borrower?.borrower,
    borrowerRestriction: state.operatorServiceCenter.borrowerRestriction,
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
    }
  }, [dispatch])

  const handleBackButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE))
  }

  const handleBackToHomeButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE))
    history.push(PagesPathEnum.HOME_PAGE)
  }

  return (
    <>
      <ArrowBackButton onBackButtonClick={handleBackButton} />
      <MainContainer>
        <Card>
          <CardContainer>
            <CardHeader>
              <Title>{borrowerRestriction}</Title>
            </CardHeader>

            <TextContainer>
              <Text>
                Solicite ao cliente que entre em contato com a Ume através da Central de Atendimento pelo WhatsApp,
                utilizando o número {''}
                {<BoldText>{formatCellphoneWithoutNinthDigit(UmeConfigUtils.CUSTOMER_SERVICE_WHATSAPP)}</BoldText>}, ou
                através do número {''}
                {<BoldText>{formatDDGPhoneNumber(UmeConfigUtils.CUSTOMER_SERVICE_CENTER_PHONE_NUMBER)}</BoldText>} para
                ligação.
              </Text>
            </TextContainer>

            <CardSectionInfo>
              <BoldText>CPF</BoldText>
              <BoldText color={gray.text}>{DocumentUtils.maskCpf(borrower?.document)}</BoldText>
            </CardSectionInfo>
          </CardContainer>
        </Card>
      </MainContainer>

      <Footer>
        <BackButton onClick={handleBackToHomeButton}>Sair</BackButton>
      </Footer>
    </>
  )
}

export default OperatorServiceCenterBorrowerRestriction

const Title = styled.text`
  font-family: Roboto;
  font-size: 21px;
  font-weight: bold;
  color: ${red.dark};
  text-align: left;
`

const TextContainer = styled.div`
  padding: 30px 0px;
`

const Text = styled.text`
  font-family: Roboto;
  font-size: 16px;
  color: ${black.primary};
  text-align: left;
`
