import { Button } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { history } from '../../../App'
import { ReactComponent as ApplicationDeniedIcon } from '../../../common/assets/images/application_denied.svg'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'
import ApplicationSliceReducer from '../../../redux/reducers/application/application.reducer'

const ApplicationDeniedPage = () => {
  const dispatch = useDispatch()

  const handleReset = () => {
    dispatch(ApplicationSliceReducer.actions.resetState())
    history.push(PagesPathEnum.OPERATOR_SERVICE_CENTER)
  }
  return (
    <Container>
      <div>
        <ApplicationDeniedIcon style={{ height: '100%', width: '100%' }} />
      </div>

      <TextContainer>
        <Title>Cadastro não aprovado...</Title>

        <ExplanationContainer>
          <Text>
            <strong>Infelizmente não conseguimos</strong> atender esse cliente.
          </Text>

          <Text>
            <strong>Enviamos um SMS</strong> ao número cadastrado com mais informações.
          </Text>
        </ExplanationContainer>
      </TextContainer>

      <BackButton onClick={handleReset}> Voltar para a tela de cadastro </BackButton>
    </Container>
  )
}

export default ApplicationDeniedPage

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  margin-top: 5vh;
`

const ExplanationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 50%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px 32px 24px;
  justify-content: space-between;

  text-align: left;
  height: 93%;

  // For Desktop
  max-width: 400px;
  margin: auto;
`

const Title = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  letter-spacing: 0.18px;

  color: black;
`

const Text = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #92929d;
`

const BackButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #ffffff !important;

  // BUTTON
  padding: 20px !important;
  border: 1px solid #11b048 !important;
  background: #3350ff !important;
  border-radius: 10px !important;

  height: 100px;

  width: 90% !important;

  align-self: center;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`
